import React, { useContext, useEffect, useMemo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import { useHistory } from "react-router-dom";


import { Context } from '../../context';

import { Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Mensagem from '../../componentes/Mensagem/index';

import { logout } from '../../servicos/autenticacao';
import { mascaraCPF, formatarDataParaIso, getSistema } from '../../util/ferramentas';

import { ContainerLogin } from './styles';

export default function Login() {
  const history = useHistory();

  const {
    setCpfUsuario,
    setNascimentoUsuario,
    submeterLogin,
    cpfUsuario,
    nascimentoUsuario,
    error,
    setError,
    setIsLoading,
    isLoading,
  } = useContext(Context);

  useEffect(() => {
    setError(false);
    setNascimentoUsuario(null);
    setCpfUsuario('');
    logout();
  }, [setNascimentoUsuario, setCpfUsuario, setError])

  function manipulaInput(e) {
    setCpfUsuario(mascaraCPF(e));
  }

  function manipulaData (data) {
    setNascimentoUsuario(data);
  }

  async function manipulaEntrada(e) {
    const rota = '/formularios';
    const cpfInvalido = 'CPF inválido, digite os 11 números de seu CPF.';
    const nascimentoInvalido = 'Preencha sua data de nascimento no formato DD/MM/AAAA.';
    setIsLoading(true);

    e.preventDefault();

    if(cpfUsuario.length < 14) {
      setError(cpfInvalido);
      return;
    } else if(isNaN(nascimentoUsuario) || !nascimentoUsuario) {
      setError(nascimentoInvalido);
      return;
    }

    submeterLogin(cpfUsuario, formatarDataParaIso(nascimentoUsuario), history, rota);
  }

  const logo = useMemo(() => {
    const sistema = getSistema();
    const logoSvg = `/${sistema}/logo.svg`;

    return <img src={logoSvg} alt={`Logo ${sistema}`} />
  }, []);

  return (
    <ContainerLogin>
      <header>{logo}</header>
      <Mensagem
        texto={error}
        tipo="error"
        exibicao={error}
        onChange={setError}
        setIsLoading={setIsLoading}
      />
      <form onSubmit={manipulaEntrada}>
        <TextField
          color="secondary"
          value={cpfUsuario}
          label="CPF"
          inputProps={{
            inputMode: "numeric"
          }}

          disabled={isLoading}
          autoComplete="off"
          onChange={e => manipulaInput(e.target.value, 'CPF')}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <KeyboardDatePicker
            color="secondary"
            label="Data de Nascimento"
            id="data-nascimento"
            inputProps={{
              inputMode: "numeric"
            }}

            disabled={isLoading}
            format="dd/MM/yyyy"
            variant="inline"
            margin="normal"

            autoOk={true}
            invalidDateMessage="Data inválida"
            maxDateMessage="Digite uma data válida"
            minDateMessage="Digite uma data válida"

            value={nascimentoUsuario}
            onChange={manipulaData}

            autoComplete="off"
          />
        </MuiPickersUtilsProvider>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isLoading}
        >{isLoading ? 'Entrando...' : 'Entrar'}</Button>
      </form>
    </ContainerLogin>
    )
  }
