import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Context } from '../../context';

import BotaoVoltar from '../../componentes/BotaoVoltar'
import Lista from '../../componentes/Lista';

const Sessao = ({ location }) => {
  let { id_formulario } = useParams();
  const { getLista, sessoes, error } = useContext(Context);

  /**
   * Ao montar o componente busca as sessoes baseado no formulario e no evento
   */
  useEffect(() => {
    const evento = location.search;
    getLista({ lista: 'sessoes', id_formulario, evento });
  }, [getLista, id_formulario, location.search]);

  /**
   * Monitora as sessões e verifica se a sessao anterior já foi concluido, caso sim, libera a próxima.
   */
  useEffect(() => {
    sessoes.map((sessao, indice) => {
      const sessaoAnterior = sessoes.find((s, i) => i === indice - 1);

      if (sessao.travavel) {
        if (sessaoAnterior?.percentual_concluido >= 100) {
          sessao.travaSessao = false;
        } else {
          sessao.travaSessao = true;
        }
      } else {
        sessao.travaSessao = false;
      }

      return true;
    })
  }, [sessoes]);

  return (
    <>
      <BotaoVoltar
        location="/formularios"
        titulo="Sessões"
      />
      <Lista
        items={sessoes}
        url='avaliacoes_url'
        listaVazia={error ? error : "Nenhuma sessão encontrada."}
        progresso
      />
    </>
  )
}

export default Sessao;
