import React, { useContext, useEffect } from 'react';

import { Context } from '../../context';

import Lista from '../../componentes/Lista';
import BotaoVoltar from '../../componentes/BotaoVoltar'

const Formulario = () => {
	const { getLista, formularios, error } = useContext(Context);

	const listItems = formularios.map(item => ({
		ativo: item.ativo,
		titulo: item.titulo,
		subtitulo: item.evento_descricao,
		sessoes_url: item.sessoes_url,
	}));

	useEffect(() => {
		getLista({ lista: 'formularios' });
	}, [getLista]);

	return (
		<>
			{/* TODO:
				- Criar componente toolbar, podendo passar componente(s) pra ele.
			*/}
			<BotaoVoltar
				location="/"
				titulo="Formulários"
			/>
			<Lista
				items={listItems}
				listaVazia={error ? error : "Você não tem permissão para utilizar este aplicativo!"}
				url='sessoes_url'
			/>
		</>
	);
}

export default Formulario;
