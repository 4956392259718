import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './util/cssGlobal/reset.css';
import './util/cssGlobal/index.css';
import { getSistema } from './util/ferramentas';

document.querySelector(':root').classList.add(getSistema());

ReactDOM.render(<App />, document.getElementById('root'));
