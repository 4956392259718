import React, { useMemo } from 'react';

import MUIThemeProvider from './temas/provider';
import ContextProvider from './context'
import Rotas from './rotas';
import { Helmet } from 'react-helmet';
import { getSistema } from './util/ferramentas';
import GlobalStyle from './componentes/GlobalStyle';

function App() {
  const reactHelmet = useMemo(() => {
    const sistema = getSistema();
    const favicon = `/${sistema}/favicon.ico`;
    const manifest = `/${sistema}/manifest.json`;

    return (
      <Helmet>
        <link rel="manifest" href={manifest} />

        <link rel="icon" href={favicon} />
        <link rel="shortcut icon" href={favicon}/>
        <link rel="apple-touch-icon" href={favicon}/>
      </Helmet>
    );
  }, []);

  return (
    <MUIThemeProvider>
      <GlobalStyle />
      {reactHelmet}
      <ContextProvider>
        <Rotas />
      </ContextProvider>
    </MUIThemeProvider>
  );
}

export default App;
