export function irParaNovaRota(history, rota = '') {
  history.push(rota);
}

export function mascaraCPF (cpf) {
  return cpf
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function formatarDataParaIso(data) {
  if(!data) {
    return
  }
  return data.toISOString().slice(0,10)
}

export function scrollToX(element, to, duration) {
  var start = element.scrollLeft,
  change = to - start,
  currentTime = 0,
  increment = 20;

  var animateScroll = function(){
    currentTime += increment;
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
  animateScroll();
}
//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

let enviroment = '';
export function getAmbiente() {
  if (enviroment.length) return enviroment

  const { hostname } = window.location; // it can set string for test
  const isLocal = !hostname.match(/(^sima(-develop)?\.(.+)\.(com|org)\.br$)|^app-sima.+/)

  if (isLocal) {
    enviroment = 'local'
  } else if (hostname.includes('develop')) {
    enviroment = 'develop'
  } else {
    enviroment = 'production'
  }

  return enviroment;
}


let sistema = '';
export function getSistema() {
  if (sistema.length) return sistema;

  const { hostname } = window.location;
  const [match] = hostname.match(/libcom|arco/) || [];

  if (match) {
    sistema = match
  }

  return sistema
}

let schema = '';
export function getSchema() {
  if (schema.length) return schema

  const sistema = getSistema();

  if (sistema === 'arco') {
    schema = 'arco'
  } else if (sistema === 'libcom') {
    schema = 'libcom'
  } else if (sistema === 'reinaldoalguz') {
    schema = 'z00001ra'
  }

  return schema;
}

let apiUrl = '';
export function getApiUrl() {
  if (apiUrl.length) return apiUrl;

  const enviroment = getAmbiente();

  if (enviroment === 'develop' || enviroment === 'local') {
    apiUrl = 'https://api-sima-develop.rj.r.appspot.com';
  } else {
    apiUrl = 'https://api-sima-production.rj.r.appspot.com';
  }

  return apiUrl;
}
