import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    &.libcom {
      --cor-primaria: #D8B569;
      --cor-primaria-dark: #b28b37;
      --cor-secundaria: #3c2515;
      --cor-secundaria-button:#3c2515;
      --cor-secundaria-titulo: #3c2515;
    }

    &.arco {
      --cor-primaria: #037b96;
      --cor-primaria-dark: #00b17d;
      --cor-secundaria: #444;
      --cor-secundaria-button: #029b8f;
      --cor-secundaria-titulo: #f8f8f8;
    }

    --cor-fundo-cinza: #d6d4d2;
    --cor-fundo-dark-opacity: rgba(0, 0, 0, 0.87);
    --cor-azul-100: #67a2e9;
    --cor-azul-200: #468ee5;
    --cor-azul-300: #468ee5;
    --cor-azul-400: #3a75bc;
    --cor-verde-100: #bef3be;
    --cor-verde-200: #93fb93;
    --cor-verde-300: #1bc51b;
    --cor-verde-400: #109810;
    --cor-vermelho-100: #d22424;
    --cor-vermelho-200: rgb(138, 25, 23);
    --cor-vermelho-300: #731312;
    --cor-vermelho-400: #5a0f0e;
    --cor-cinza-100: #eee;
    --cor-cinza-200: #9b9b9b;
    --cor-fundo-light-1: #fff;
    --cor-fundo-light-2: #f8f8f8;
    --cor-fundo-light-3: #f2f2f2;
    --cor-fundo-opaco: rgba(238, 238, 238, 0.5);
    --cor-fundo-dark-1: #343434;
    --cor-fundo-dark-2: #9b9b9b;
    --cor-fundo-dark-3: #a8a8a8;
    --cor-fundo-dark-4: #e4e4e4;
    --cor-texto-dark-0: rgba(0, 0, 0, 0.7);
    --cor-texto-dark-alpha: rgba(0, 0, 0, 0.4);
    --cor-texto-dark-1: #4a4a4a;
    --cor-texto-dark-2: #9b9b9b;
    --cor-texto-dark-3: #a8a8a8;
    --cor-texto-dark-4: #dfdedf;
    --cor-texto-light-1: #fff;
    --cor-texto-light-2: #f8f8f8;
    --cor-texto-light-3: #a8a8a8;
    --cor-branco: #fff;
    --cor-preto: #000;
  }

`;
