import React, { createContext, useState, useCallback} from 'react';

import { get, post } from './servicos/api';
import { login } from './servicos/autenticacao';
import { getSchema, irParaNovaRota } from './util/ferramentas';

export const Context = createContext();

const ContextProvider = props => {
  const [ error, setError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const [ cpfUsuario, setCpfUsuario ] = useState('');
  const [ nascimentoUsuario, setNascimentoUsuario ] = useState(new Date());

  const [ formularios, setFormularios ] = useState([]);
  const [ sessoes, setSessoes ] = useState([]);
  const [ avaliacoes, setAvaliacoes ] = useState([]);

  async function submeterLogin(cpf, dtnascimento, history, rota) {
    // cpf: "399.567.608-11",
    // dtnascimento: "1990-09-29"

    try {
      let corpo = {
        cpf,
        dtnascimento
      };
      const response = await post(`/auth/login`, corpo);
      login(response.token);
      irParaNovaRota(history, rota);

      // TODO: atualizar estado com objeto do usuario logado {id, nome, avatar, etc}.
    } catch (error) {
      setError(error.message);
    }
  }

  const getLista = useCallback(async function(params) {
    const { lista, id_formulario, id_sessao, evento } = params;

    setIsLoading(true);
    try {
      if(lista === 'formularios') {
        const response = await get('/formularios');
        setFormularios(response);
      } else if (lista === 'sessoes') {
        const response = await get(`/formularios/${id_formulario}/sessoes${evento}`);
        setSessoes(response);
      } else if (lista === 'avaliacoes') {
        const response = await get(`/formularios/${id_formulario}/sessoes/${id_sessao}/avaliacoes${evento}`)
        setAvaliacoes(response);
        setSessoes(response.sessoes)
      }
    }
    catch (error) {
      console.log(error);
      if(lista === 'formularios')
        setFormularios([]);
      if(lista === 'sessoes')
        setSessoes([]);
      if(lista === 'avaliacoes')
        setAvaliacoes([]);
      setError(error.message);
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        cpfUsuario,
        setCpfUsuario,
        nascimentoUsuario,
        setNascimentoUsuario,
        setAvaliacoes,
        submeterLogin,
        getLista,
        formularios,
        sessoes,
        avaliacoes,
        schema: getSchema(),
        isLoading,
        setIsLoading,
        setError,
        error,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default ContextProvider;
