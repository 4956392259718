import React, { useMemo } from 'react';

import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { getSistema } from '../util/ferramentas';

function MUIThemeProvider({ children }) {
  const tema = useMemo(() => {
    const sistema = getSistema();
    const PALETA_PADRAO = {
      primary: {
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      secondary: {
        light: 'rgba(0, 0, 0, 0.87)',
        main: '#000000',
        contrastText: '#fff',
      }
    };

    return createTheme({
      palette: {
        primary: {
          ...PALETA_PADRAO.primary,
          main: sistema === 'arco' ? '#037b96' : '#d8b569',
        },
        secondary: PALETA_PADRAO.secondary,
      }
    })
  }, []);

  return (
    <ThemeProvider theme={tema}>
      {children}
    </ThemeProvider>
  )
}

export default MUIThemeProvider;
